@font-face {
  font-family: Poppins;
  src: url(/public/fonts/Poppins/Poppins-Regular.ttf);
}

@font-face {
  font-family: Poppins;
  src: url(/public/fonts/Poppins/Poppins-Italic.ttf);
  font-style: italic;
}

@font-face {
  font-family: Poppins;
  src: url(/public/fonts/Poppins/Poppins-Bold.ttf);
  font-weight: bold;
}

body {
  background-color: rgb(220, 245, 220);
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

header {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: start;
  height: 64px;
  background-color: rgb(245, 255, 245);
  position: sticky;
  top: 0;
  z-index: 100;
  box-shadow: 0 0 10px rgb(80, 80, 80);
}

.logo-box {
  flex-basis: content;
  padding-left: 14px;
  padding-right: 4px;
}

.logo {
  height: 36px;
}

.logo-text {
  padding-top: 18px;
  flex-basis: content;
  font-family: 'Poppins';
  font-size: 1.6em;
  justify-items: end;
  color: #ababab;
}

.lang-buttons {
  flex-basis: 100%;
  text-align: end;
}

.pagination {
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

.panel {
  background-color: rgb(245, 255, 245);
  border-radius: 15px;
  padding: 0.5em;
  box-shadow: 0 0 10px rgb(80, 80, 80);
}

.small-shadow {
  box-shadow: 0 0 10px rgb(80, 80, 80);
}

.step {
  width: 100vw;
  height: auto;
  opacity: 1;
  transition: opacity 0.2s linear;
}

.hide {
  width: 0;
  height: 0;
  padding: 0;
  opacity: 0;
  margin: 0;
  overflow: hidden;
}

.variety {
  background-color: aquamarine;
  border-radius: 10px;
  font-size: 0.7em;
  font-weight: bold;
}

.all {
  background-color: lightgrey;
}

.green {
  background-color: rgb(125, 238, 125);
}

.red {
  background-color: rgb(255, 102, 102);
}

.garnet {
  background-color: rgb(203, 113, 113);
}

.yellow {
  background-color: rgb(252, 222, 131);
}

.title {
  font-size: 1em;
  font-weight: bold;
  text-align: start;
  padding: 0.1em 0.5em;
  margin-bottom: 0.5em;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.crop {
  text-align: start;
  padding: 0.2em 0.2em;
  font-size: 1em;
  font-weight: bold;
}

.selected {
  background-color: rgb(185, 205, 150);
  box-shadow: 0 0 5px grey;
}

.maxw {
  max-width: 960px;
}

.anim {
  transition: all 0.1s linear;
}

.filter {
  box-shadow: 0 0 5px black;
  transform: scale(1.05);
}

.map {
  position: absolute;
  border: 0;
  z-index: -1;
  top: 64px;
  left: 0;
  margin: 0;
  padding: 0;
  width: 100vw;
  height: calc(100vh - 66px);
}

.green-tag {
  background-color: #ffdda5;
  border-radius: 8px;
  color: #000000;
  font-size: 14px;
  padding: 0;
  position: relative;
  box-shadow: -2px -2px 5px grey;
}

.green-tag::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 100%;
  transform: translate(-50%, 0);
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #ffdda5;
}

.green-tag-title {
  text-align: start;
  font-weight: bold;
  background-color: rgb(0, 0, 0, 0.15);
  padding: 5px 20px 5px 5px;
  margin: 0;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.green-tag-value {
  padding: 10px 5px;
}

.green-tag-max {
  background-color: #ffc26c;
  border-radius: 8px;
  color: #000000;
  font-size: 14px;
  padding: 0;
  position: relative;
  box-shadow: -2px -2px 5px grey;
  transform: scale(1.2);
}

.green-tag-max::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 100%;
  transform: translate(-50%, 0);
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #ffc26c;
}

.green-a {
  position: relative;
  margin: 0 auto;
  padding: 2px;
  width: fit-content;
  background-color: #ababab;
  border-radius: 5px;
  overflow: visible;
}

.green-a > div {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  width: fit-content;
}

.green-a > div > div {
  border-radius: 3px;
  margin: 3px;
  width: 45px;
  height: 25px;
  background-color: rgb(224, 224, 224);
}

.green-b {
  position: relative;
  margin: 0 auto;
  padding: 2px;
  width: fit-content;
  background-color: #ababab;
  border-radius: 5px;
  overflow: visible;
}

.green-b > div {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  width: fit-content;
}

.green-b > div > div {
  border-radius: 3px;
  margin: 3px;
  width: 30px;
  height: 25px;
  background-color: rgb(224, 224, 224);
}

.green-c {
  position: relative;
  margin: 0 auto;
  padding: 2px;
  width: fit-content;
  background-color: #ababab;
  border-radius: 5px;
  overflow: visible;
}

.green-c > div {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  width: fit-content;
}

.green-c > div > div {
  border-radius: 3px;
  margin: 3px;
  width: 35px;
  height: 25px;
  background-color: rgb(224, 224, 224);
}

.corridor {
  width: 25px !important;
  background-color: #ababab !important;
}

.all {
  background-color: lightgrey !important;
}

.green {
  background-color: rgb(125, 238, 125) !important;
}

.red {
  background-color: rgb(255, 102, 102) !important;
}

.garnet {
  background-color: rgb(203, 113, 113) !important;
}

.yellow {
  background-color: rgb(252, 222, 131) !important;
}

.sector-y {
  position: absolute;
  top: 0;
  left: -40px;
  padding: 2px 0;
  display: flex;
  flex-direction: column;
  background-color: #ababab;
  border-radius: 5px;
}

.sector-y > div {
  width: 25px;
  height: 25px;
  margin: 3px;
  font-size: 14px;
  font-weight: bold;
  color: #323232;
}

.sector-x {
  position: absolute;
  bottom: -40px;
  left: 2px;
  padding: 2px 0;
  display: flex;
  flex-direction: row;
  background-color: #ababab;
  border-radius: 5px;
}

.sector-x > div {
  background-color: #ababab !important;
  width: 50px;
  height: 25px;
  margin: 3px;
  font-size: 14px;
  font-weight: bold;
  color: #323232;
}

.open-view {
  height: 70vh;
}